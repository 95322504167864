<template>
  <div>
    <el-card class="box-card">
      <div class="search-table-box">
        <div>
          <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
            <el-form-item label="关键字">
              <el-input style="width: 300px" clearable v-model="queryParams.searchName" placeholder="志愿者姓名/手机号/身份证号">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
            <div class="down-export">
              <el-button type="primary" @click="add()">新增</el-button>
              <el-button type="primary" @click="downloadTemp()">模板下载</el-button>
              <el-button type="primary" @click="uploadVisible = true">导入</el-button>
              <el-button type="primary" plain @click="downLoad">导出</el-button>
            </div>
          </el-form>
        </div>
        <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border
          :header-cell-style="headerCellStyle">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="volunteerName" label="姓名" align="center" />
          <el-table-column prop="idPhoto" label="证件照" align="center">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.idPhoto" fit="contain" v-if="scope.row.idPhoto"></el-image>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别" align="center">
            <template slot-scope="scope">
              <span>{{ getContent(SEXLIST, scope.row.gender, 'label', 'text') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="idNumber" label="身份证号" align="center" />
          <el-table-column prop="telephone" label="手机号" align="center" />
          <el-table-column prop="address" label="家庭地址" align="center" />
          <el-table-column label="操作" width="250" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
              <el-button type="text" @click="scoreRecord(scope.row)">积分记录</el-button>
              <el-button type="text" @click="removeDialog(scope.$index,scope.row,tableData)" style="color:rgb(255, 90, 89)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" justify="end" class="total-row">
           <div>志愿者<span>{{total}}</span>名</div>
        </el-row>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 上传 -->
    <UploadFile url="/volunteerManage/importVolunteerManage" :uploadVisible.sync="uploadVisible"
      @updateList="searchList" />
  </div>
</template>

<script>
import UploadFile from '@/components/uploadFile'
import { downloadStream,getContent } from '@/utils/index'
import { SEXLIST } from '@/data/dataDictionary'
import {
  getVolunteerManageList,
  downloadVolunteerManage,
  exportVolunteerMangeExcel,
  deleteVolunteer
} from '@/api/volunteerManage'
export default {
  components: {
    UploadFile
  },
  data() {
    return {
      SEXLIST,
      uploadVisible: false,
      loading: false,
      tableData: [],
      queryParams: {
        //查询参数
        pageNum: 1,
        pageSize: 10,
        searchName: ''
      },
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getContent,
    add() {
      this.$router.push({
        path: '/volunteer/volunteerManage/add',
        query: { type: 1 }
      })
    },
    edit(row) {
      this.$router.push({
        path: '/volunteer/volunteerManage/edit',
        query: { info: JSON.stringify(row), type: 2 }
      })
    },
    toDetail(row) {
      this.$router.push({
        path: '/volunteer/volunteerManage/details',
        query: { info: JSON.stringify(row), type: 3 }
      })
    },
    scoreRecord(row) {
      this.$router.push({
        path: '/volunteer/integralManage/integralRecord',
        query: { volunteerId: row.id,volunteerName: row.volunteerName }
      })
    },
    async remove(i,row,rows) {
      console.log(row)
      const res = await deleteVolunteer({ id: row.id })
      rows.splice(i, 1);
      // row.status === 1 ? (row.status = 0) : (row.status = 1)
      this.$message.success(res.data)
    },
    removeDialog(i,row,rows) {
      this.$confirm(`是否删除${row.volunteerName}这名志愿者`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(i,row,rows)
      })
    },
    downloadTemp() {
      downloadVolunteerManage({}).then((res) => {
        downloadStream(res, '志愿者管理模板.xlsx')
      })
    },
    async downLoad() {
      const res = await exportVolunteerMangeExcel(this.queryParams)
      downloadStream(res, '志愿者管理表.xlsx')
    },
    async getList() {
      this.loading = true
      const res = await getVolunteerManageList(this.queryParams)
      this.loading = false
      this.tableData = res.data.rows
      this.total = res.data.total
    },
    searchList() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  min-height: calc(100% - 10px);
}
.red {
  color: rgb(255, 90, 89);
}
.search-table-box {
  .down-export {
    float: right;
    margin-bottom: 20px;
    display: flex;
    button {
      margin-left: 10px;
    }
    button:first-child {
      margin: 0 10px;
    }
  }
}
.total-row {
  margin-top: 10px;
  font-size: 14px;
  div {
    color: #606266;
  }
  span {
    color:rgb(255, 90, 89);
  }
}
/deep/ .upload-demo {
  text-align: center;
}
</style>
